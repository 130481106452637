import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Spiner } from "../Spiner";
import Paginado from "../Consola usuarios/Paginado";
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { bussine_transactions } from "../../redux/actions";
import Swal from "sweetalert2";
const MovimeintosBussine = (body) => {
  const mov = useSelector((state) => state.moviments_bussine);
  const total = useSelector((state) => state.totalMovBussine);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [desde,setDesde] = useState("")
  const [hasta,setHasta] = useState("")
  useEffect(() => {
    if (body) {
      const fetchMovements = async () => {
        try {
          setLoading(true);
          const data = {
            cuit: body.cuit,
            page: page,
            fechaDesde:desde,
            fechaHasta:hasta
          };
          // Ejecutar la acción para obtener los movimientos
          await dispatch(bussine_transactions(data));
        } catch (error) {
          console.error("Error fetching mov:", error);
        } finally {
          setLoading(false)
        }
      };

      fetchMovements(); // Llamar la función async
    }
  }, [page])
// Obtener la fecha de hoy en formato YYYY-MM-DD
  const today = new Date().toISOString().split("T")[0];

  const handleDesdeChange = (e) => {
    const newDesde = e.target.value;
    setDesde(newDesde)

    // Ajustar la fecha "Hasta" si no es válida
    if (hasta && newDesde >= hasta) {
      setHasta("") // Limpiar "Hasta" si no es válida
    }
  }

 const handleHastaChange = (e) => {
    const newHasta = e.target.value;
    if (newHasta >= desde && newHasta < today) {
      setHasta(newHasta)
    }
  }

  const searchWhitDate =async()=>{
    const data = {
      cuit: body.cuit,
      page: page,
      fechaDesde:desde,
      fechaHasta:hasta
    };
    const response = await dispatch(bussine_transactions(data))
    setPage(1)
   if(response?.status ===400){
   Swal.fire({
    title: '',
    text: response.data?.msg,
    icon: 'error',
    confirmButtonText: 'Aceptar'
   })
  }
   //console.log(response)
  }

  const formatDate = (fecha) => {
    if (!fecha) return "";
    
    const dateObj = new Date(fecha); // Convertir a objeto Date
    const dia = String(dateObj.getUTCDate()).padStart(2, "0"); 
    const mes = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); 
    const año = dateObj.getUTCFullYear();
  
    return `${dia}-${mes}-${año}`;
  };
  
  
  const capitalizeName = (name) => {
    if (!name) return ""
    return name
      .toLowerCase() // Convertir todo a minúscula
      .replace(/\b\w/g, (char) => char.toUpperCase()) // Convertir la primera letra de cada palabra en mayúscula
  }
  return (
    <div>
      <div className="pb-2 d-flex align-items-center">
        <div className="d-flex align-items-center ms-3">
          <Link onClick={body.closeModaldetail}>
            <IoArrowBackCircleOutline
              style={{ fontSize: "30px", color: "orange" }}
            />
          </Link>
         
        </div>
      </div>
      <p className="fs-3 ms-3">Movimientos</p>
      <div className="d-flex align-items-center ms-3">
         
           <label htmlFor="">Desde:</label>
          <input max={today} value={desde} onChange={handleDesdeChange}  type="date" name="desde" id="" className="form-control ms-2" style={{width: "20%"}}/>
          <label htmlFor="">Hasta:</label>
          <input max={today} value={hasta} onChange={handleHastaChange} type="date" name="hasta" id="" className="form-control ms-2" style={{width: "20%"}}/>
          <button className="btn ms-2" style={{backgroundColor: "var(--naranja)", color: "white"}} onClick={searchWhitDate}>Buscar</button>

      </div>
      <div id="#movimientos" style={{ padding: "20px" }}>
        <div>
          {loading ? (
            <Spiner />
          ) : !mov || mov?.length === 0 ? (
            <div className="alert alert-warning" role="alert">
              No se encontraron movimientos.
              <BiSearch style={{ fontSize: "30px" }} />
            </div>
          ) : (
            <table className="table">
              <thead>
                <tr>
                <th scope="col">Fecha</th>
                  <th scope="col">Nombre Oigen</th>
                  <th scope="col">Nombre de destino</th>
                  <th scope="col">Cuit Destino</th>
                  <th scope="col">CVU Destino</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Monto(AR$)</th>
                </tr>
              </thead>
              <tbody>
                {mov?.map((el) => (
                  <tr key={el._id}>
                    <td>{formatDate(el?.timestamp)}</td>
                    <td>{capitalizeName(el.movimiento?.nameOrig)} {capitalizeName(el.movimiento?.lastNameOrig)}</td>
                    <td>{capitalizeName(el.movimiento?.nameDest)} {capitalizeName(el.movimiento?.lastNameDest)}</td>
                    <td>{el.movimiento?.cuitDest}</td>
                    <td>{el.movimiento?.cvuDest}</td>
                    <td>{el.movimiento?.types}</td>
                    <td>{el.movimiento?.balance}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <Paginado pagina={page} data={total} setPagina={setPage} />
      </div>
    </div>
  );
};

export default MovimeintosBussine;
