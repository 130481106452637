import React from "react";
import s from "../../styles/Home.module.css";
import { Usuarios } from "./Usuarios";
import { useState, useEffect ,useRef} from "react";
import { useDispatch ,useSelector} from "react-redux";
import Nav from "../dashboard/Nav";
import { getAdmin, getTransacciones,getUsers,getAsociados, get_account_balance} from "../../redux/actions";
import Sidebar2 from "../dashboard/Sidebar2";
import { Routes, Route } from "react-router-dom";
import Login from '../Login';
import DetalleUsuarios from '../Consola usuarios/DetalleUsuario';
import 'bootstrap/dist/css/bootstrap.min.css';
import Registrer from '../Registrer';
import Movimientos from '../Consola usuarios/Movimientos';
import Administradores from '../Operadores/Administradores';
import Error from '../Error';
import ListaTransacciones from "../Transacciones/ListaTransacciones";

import Agencia from "../../components/Registro red susa/Agencia"
import Operador from "../../components/Registro red susa/Operador"
import Transporte from "../../components/Registro red susa/Transporte"
import { DetalleBusquedaCvuAlias } from "../Transacciones/DetalleBusquedaCvuAlias";

import { useNavigate } from "react-router-dom";
import PrivateRoute  from "../PrivateRoute";
import Comercio from "../Registro red susa/Comercio";
import TransporteList from "../Listas/TransporteList"
import ShopsList from "../comercio/ShopsList"
import Impuestos from "../Impuestos/Impuestos";
import ImpuestosList from "../Listas/ImpuestosList";
import PerfilAdmin from "../dashboard/PerfilAdmin";
import Detalle_Tranferencia from "../Transacciones/Detalle_Tranferencia";
import Asociados from "../Consola usuarios/Asociados"
import DetalleAsociado from "./DetalleAsociado";
import ListComprobantes from "../comercio/ListComprobantes";
import RolesPermiss from "../RolesPermiss";
import MovimeintosBussine from "../Bussiness/MovimientosBussine";
import Conciliacion from "../Conciliacion";
import Alert from "../Alert";
import SaldosCvu from "../auditorias/SaldosCvu";
import Config from "../Config";
import Reverso from "../reverso/Reverso";
import ReversoTransfer from "../reverso/ReversoTransfer";
import DescargarArchivo from "../Padron/DescargarArchivo";
import PassengerByDni from "../auxiliares/PassengerByDni";

const Home = () => { 
  const dispatch = useDispatch();
  const navigate= useNavigate()
  const  isAuthenticated = useSelector(state=> state.isAuthenticated)
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const body = {
    obp_status:"",
    obp_from_date: "",
    obp_to_date:"",
    obp_limit :200,
    obp_offset:0,
    obp_origin:""
  };

  useEffect(() => {
   //dispatch(get_account_balance())
    const lastPath = localStorage.getItem('lastPath');

    if (isAuthenticated && isInitialLoad) {
      //navigate(lastPath || '/usuarios');
      setIsInitialLoad(false);
    } else if (!isAuthenticated && isInitialLoad) {
      navigate('/');
      setIsInitialLoad(false);
    }

   
  }, [isAuthenticated, navigate, dispatch, isInitialLoad]);


  return (
    <>

      <Routes>
      <Route
        path="/"
        element={
          <div >

            <Login />
          </div>
        }
      />
      <Route >
        <Route
          path="/home"
          element={
            <div className={s.contenedorPrincipal}>
              <div className={s.side}>
                <Sidebar2 />
              </div>
              <div className={s.contenido}>
              
              </div>
            </div>
          }
        />
    
      <Route
        path="/detail/:data"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
              <DetalleBusquedaCvuAlias/>
            </div>
          </div>
        }
      />
      <Route
        path="/usuarios"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
             
              <Usuarios />
            </div>
          </div>
        }
      />
      <Route 
        path="/operadores"
        element={
          <PrivateRoute role={['superadmin']}>
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
              <Administradores />
            </div>
          </div>
        </PrivateRoute >
        }
      />
        <Route
        path="/register"
        element={
          <PrivateRoute role={['superadmin']}>
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
             
              <Registrer />
            </div>
          </div>
          </PrivateRoute>
        }
      />
      
      <Route
        path="/transacciones"
        element={
          <PrivateRoute role={['superadmin']}>
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
          
              <ListaTransacciones/>
            </div>
          </div>
          </PrivateRoute>
        }
      />
    
    
      <Route
        path="/usuarios/:dni"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
              <DetalleUsuarios />
            </div>
          </div>
        }
      />
      <Route
        path="/user/movimiento/:id"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
             
              <Movimientos />
            </div>
          </div>
        }
      />
      <Route
        path="*"
        element={
          <div >
              <Error />
          </div>
        }
      />
       <Route
        path="/add/agencia"
        element={
          <div className={s.contenedorPrincipal}>
          <div className={s.side}>
            <Sidebar2 />
          </div>
          <div className={s.contenido}>
         
            <Agencia />
          </div>
        </div>
        }
      />
       <Route
        path="/add/operador"
        element={
          <div className={s.contenedorPrincipal}>
          <div className={s.side}>
            <Sidebar2 />
          </div>
          <div className={s.contenido}>
           
            <Operador />
          </div>
        </div>
        }
      />
       <Route
        path="/add/transporte"
        element={
          <div className={s.contenedorPrincipal}>
          <div className={s.side}>
            <Sidebar2 />
          </div>
          <div className={s.contenido}>
          
            <Transporte />
          </div>
        </div>
        }
      />
      <Route
        path="/add/impuesto"
        element={
          <div className={s.contenedorPrincipal}>
          <div className={s.side}>
            <Sidebar2 />
          </div>
          <div className={s.contenido}>
          
           <Impuestos/>
          </div>
        </div>
        }
      />
    

       <Route
        path="/add/comercio"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
           <Sidebar2/>
            </div>
            <div className={s.contenido}>
           <Comercio></Comercio>
            </div>
          </div>
        }
      />
       <Route
        path="/list/transporte"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
           <Sidebar2/>
            </div>
            <div className={s.contenido}>
          <TransporteList/>
            </div>
          </div>
        }
      />
       <Route
        path="/list/comercio"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
           <Sidebar2/>
            </div>
            <div className={s.contenido}>
        <ShopsList/>
            </div>
          </div>
        }
      />
        <Route
        path="/list/impuestos"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
           <Sidebar2/>
            </div>
            <div className={s.contenido}>
         <ImpuestosList/>
            </div>
          </div>
        }
      />
        <Route
        path="/list/transacciones"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
           <Sidebar2/>
            </div>
            <div className={s.contenido}>
            <ListaTransacciones/>
            </div>
          </div>
        }
      />
     </Route>
     <Route
        path="/perfil"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
           <Sidebar2/>
            </div>
            <div className={s.contenido}>
            <PerfilAdmin/>
            </div>
          </div>
        }
      />
       
     
        <Route
        path="/asociado/:id"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
             <DetalleAsociado/>
            </div>
          </div>
        }
      />
       <Route
        path="/list/asociados"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
             <Asociados/>
            </div>
          </div>
        }
      />
       <Route
        path="/list/comprobantes/:id"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
             <ListComprobantes/>
            </div>
          </div>
        }
      />
        <Route
        path="/add/rol-permits"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
           <RolesPermiss/>
            </div>
          </div>
        }
      />
        <Route
        path="/mov/bussiness"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
            <MovimeintosBussine/>
            </div>
          </div>
        }
      />
       <Route
        path="/conciliacion"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
            <Conciliacion/>
            </div>
          </div>
        }
      />
       <Route
        path="/alertas"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
            <Alert/>
            </div>
          </div>
        }
      />
        <Route
        path="/auditorias/saldosCvu"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
            <SaldosCvu/>
            </div>
          </div>
        }
      />
       <Route
        path="/config"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
           <Config/>
            </div>
          </div>
        }
      />
       <Route
        path="/reverso"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
           <Reverso/>
            </div>
          </div>
        }
      />
       <Route
        path="/reverso-transfer"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
           <ReversoTransfer/>
            </div>
          </div>
        }
      />
       <Route
        path="/descargas"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
           <DescargarArchivo/>
            </div>
          </div>
        }
      />
       <Route
        path="/auxiliares/Qr"
        element={
          <div className={s.contenedorPrincipal}>
            <div className={s.side}>
              <Sidebar2 />
            </div>
            <div className={s.contenido}>
           <PassengerByDni/>
            </div>
          </div>
        }
      />
    </Routes>
    </>
  );
};

export default Home;
