import React from 'react'
import { useSelector } from 'react-redux'
import { IoArrowBackCircleOutline } from "react-icons/io5";

export const EventsQr = ( {closeModalQr}) => {
    const list = useSelector(state => state.events_nfc)
  

    return (
        <div className='container mt-4'>
            <div className='d-flex align-items-end mb-3 '>
           <div style={{border:"none", backgroundColor:"transparent", cursor:"pointer", marginRight:"10px"}}   onClick={()=>closeModalQr(false)}>
           <IoArrowBackCircleOutline
              style={{ fontSize: "30px", color: "orange", }}
              />
           </div>
              <h5>Lista de Eventos Nfc</h5>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Fecha</th>
                        <th scope="col">Motivo</th>
                        <th scope="col">Estado</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((el) =>
                        el.events.map((event, idx) => (
                            <tr key={idx}>
                                <td>{event.date}</td>
                                <td>{event.msg}</td>
                                <td>{event.status}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    )
}
