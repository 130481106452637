import React from "react";
import { get_passenger_NFC, get_passenger_QR, get_passenger_dni } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Modal from "react-modal";
import styles from "../../styles/Users.module.css";
import QrDetail from "./QrDetail";
import NfcDetail from "./NfcDetail";
import { LuNfc,LuList,LuQrCode } from "react-icons/lu";

const ListQr = () => {
  const [dni, setDni] = React.useState("");
  const [list, setList] = React.useState([]);
  const [listQr, setListQr] = React.useState([]);
  const [listNfc, setListNfc] = React.useState([]);
  const [tableShow, setTableSHow] = React.useState(false);
  const [modalQr, setModalQr] = React.useState(false);
  const [modalNfc, setModalNfc] = React.useState(false);
  const [selectedId, setSelectId]= React.useState("");
  const dispatch = useDispatch();

  useEffect(() => {}, [tableShow]);
  const searchUser = async () => {
    const response = await dispatch(get_passenger_dni(dni));

    if (response?.status === 200) {
      setList(response?.data);
      setTableSHow(true);
    } else {
      return;
    }
  };

  const getQrUser = async (id) => {
    setSelectId(id)
    const response = await dispatch(get_passenger_QR(id));

    if (response?.status === 200) {
        setModalQr(true);
        setListQr(response?.data);
       // console.log(response.data)
    } else {
      return;
    }
  };

  const closeModalQr = () => {
    setModalQr(false);
   
  };

  const getNfcUser = async (id) => {
    setSelectId(id)
    const response = await dispatch(get_passenger_NFC(id));

    if (response?.status === 200) {
        setModalNfc(true);
        setListNfc(response?.data);
       // console.log(response.data)
    } else {
      return;
    }
  };
  const closeModalNfc = () => {
    setModalNfc(false);
   
  };
  return (
    <div className="container ">
      <h5 className="mb-4 ms-1">Buscar pasajero</h5>
      <div className="row items-center">
        <div className="col-1">
          <label className="" htmlFor="">
            DNI:
          </label>
        </div>
        <div className="col-4">
          <input
            className="form-control "
            type="text"
            value={dni}
            onChange={(e) => setDni(e.target.value)}
          />
        </div>
        <div className="col-2">
          <button
            onClick={searchUser}
            className=" btn text-white "
            style={{ backgroundColor: "var(--naranja" }}
          >
            Buscar
          </button>
        </div>
      </div>

      {tableShow && (
        <table className="table mt-4">
          <thead>
            <tr>
              <th scope="col">DNI</th>
              <th scope="col">Nombre y Apellido</th>
              <th scope="col">Correo</th>
              <th scope="col">Tipo</th>
              <th scope="col">Detalle QR</th>
              <th scope="col">Detalle NFC</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.dni}</td>
                    <td>
                      {item.name} {item.lastName}
                    </td>
                    <td>{item.email}</td>
                    <td>{item.type}</td>
                    <td>
                      <button className="btn btn-sm text-primary fs-8" onClick={()=>getQrUser(item._id)}>QR <LuQrCode size={22}/></button>
                    </td>
                    <td>
                      <button className="btn btn-sm text-primary fs-8" onClick={()=>getNfcUser(item._id)}><LuNfc size={22}/>NFC</button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
        <Modal
        isOpen={modalQr}
        className={styles.modal}
        overlayClassName={styles.Overlay} //fondo atras
        onRequestClose={closeModalQr}
        contentLabel="Detalle Qr del usuario"
      >
        <QrDetail
          data={listQr}
          setListQr={setListQr}
          idUser= {selectedId}
          closeModal={closeModalQr}
        />
      </Modal>
      
      <Modal
        isOpen={modalNfc}
        className={styles.modal}
        overlayClassName={styles.Overlay} //fondo atras
        onRequestClose={closeModalNfc}
        contentLabel="Detalle NFC del usuario"
      >
        <NfcDetail
          data={listNfc}
          setListNfc={setListNfc}
          idUser= {selectedId}
          closeModal={closeModalNfc}
        />
      </Modal>
    </div>
  );
};

export default ListQr;
