import { FaShop } from "react-icons/fa6";
import { MdExitToApp, MdComputer } from "react-icons/md";

import logo from "../../img/susa-logo.png";
import s from "../../styles/sideBar.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { GrTransaction } from "react-icons/gr";
import { MdListAlt } from "react-icons/md";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { LogOut, getAdmin } from "../../redux/actions";
import { FaUsers, FaUsersCog, FaBus } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { BiTransfer } from "react-icons/bi";
import { FaChildren } from "react-icons/fa6";
import { GiReturnArrow } from "react-icons/gi";
import classnames from "classnames";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { MdBalance, MdDownload } from "react-icons/md";
import { TbAlertTriangle } from "react-icons/tb";
import { AiOutlineFileSearch } from "react-icons/ai";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { FaCog } from "react-icons/fa";
import { LuNfc, LuList, LuQrCode } from "react-icons/lu";
const Sidebar2 = () => {
  const dispatch = useDispatch();
  const [loggedInUser, setLoggedInUser] = useState(null);
  const usuario = localStorage.getItem("user-log");
  const userRole = JSON.parse(usuario)?.data_user?.role;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (usuario) {
      setLoggedInUser(JSON.parse(usuario));
    }

    if (location.pathname === "/home") {
      navigate("/usuarios");
    }
  }, [usuario, location.pathname, navigate]);

  const handleLogout = () => {
    dispatch(LogOut());
    localStorage.removeItem("user-log");
    navigate("/");
  };

  const reload = () => {
    dispatch(getAdmin());
  };

  return (
    <div className="d-flex">
      <nav className={`overflow-y-scroll ${s.sidebar}`}>
        <div className={`d-flex flex-column ${s.sidebarContent}`}>
          <Link
            to="/home"
            onClick={reload}
            className="text-center mb-4 bg-white p-2"
          >
            <img src={logo} alt="logo" className={s.logo} />
          </Link>
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link
                to="/usuarios"
                className={classnames("nav-link text-white fs-6", s.navLink, {
                  [s.active]: location.pathname === "/usuarios",
                })}
              >
                <FaUsers /> Admin. de Usuarios
              </Link>
              <hr />
            </li>
            <li className="nav-item">
              <Link
                to="/list/asociados"
                className={classnames("nav-link text-white fs-6", s.navLink, {
                  [s.active]: location.pathname === "/list/asociados",
                })}
              >
                <FaChildren /> Admin. de Asociados
              </Link>
              <hr />
            </li>
            <li className="nav-item" hidden={userRole !== "superadmin"}>
              <Link
                to="/operadores"
                className={classnames("nav-link text-white fs-6", s.navLink, {
                  [s.active]: location.pathname === "/operadores",
                })}
              >
                <FaUsersCog /> Admin. operadores de consola
              </Link>
              <hr hidden={userRole === "atc" || userRole === "encAtc"} />
            </li>
            {/* ---------------------------- */}

            <a
              hidden={userRole !== "superadmin"}
              className={classnames("nav-link text-white fs-6", s.navLink, {
                [s.active]:
                  location.pathname === "/list/transporte" ||
                  location.pathname === "/list/comercio" ||
                  location.pathname === "/list/impuestos",
              })}
              data-bs-toggle="collapse"
              href="#multiCollapseExample1"
              role="button"
              aria-expanded="false"
              aria-controls="multiCollapseExample1"
            >
              {" "}
              <MdComputer style={{ fontSize: "18px" }} /> Admin. red susa{" "}
              <IoIosArrowDown />
            </a>

            <div className="row">
              <div className="col">
                <div
                  className="collapse multi-collapse"
                  id="multiCollapseExample1"
                >
                  <ul className={`pb-3 pt-3  ${s.dropdownMenu}`}>
                    <Link
                      className={classnames(
                        "mb-3 dropdown-item text-white",
                        s.dropdownItem,
                        {
                          [s.active]: location.pathname === "/list/transporte",
                        }
                      )}
                      to="/list/transporte"
                    >
                      <FaBus style={{ marginRight: "5px" }} /> Lista de Empresas
                      Transporte
                    </Link>

                    <Link
                      className={classnames(
                        "mb-3 dropdown-item text-white",
                        s.dropdownItem,
                        {
                          [s.active]: location.pathname === "/list/comercio",
                        }
                      )}
                      to="/list/comercio"
                    >
                      <FaShop style={{ marginRight: "5px" }} /> Lista de
                      Comercios
                    </Link>

                    <Link
                      className={classnames(
                        " dropdown-item text-white",
                        s.dropdownItem,
                        {
                          [s.active]: location.pathname === "/list/impuestos",
                        }
                      )}
                      to="/list/impuestos"
                    >
                      <MdListAlt style={{ marginRight: "5px" }} /> Lista de
                      Impuestos
                    </Link>
                  </ul>
                </div>
              </div>
            </div>

            {/* ---------------------------- */}
            <hr hidden={userRole !== "superadmin"} />
            <li className="nav-item">
              <Link
                to="/list/transacciones"
                className={classnames("nav-link text-white fs-6", s.navLink, {
                  [s.active]: location.pathname === "/list/transacciones",
                })}
              >
                <BiTransfer fontSize={20} /> Conciliación
              </Link>
            </li>
            <hr hidden={userRole === "atc" || userRole === "encAtc"} />

            <li
              className="nav-item"
              hidden={userRole === "atc" || userRole === "encAtc"}
            >
              <Link
                to="/conciliacion"
                className={classnames("nav-link text-white fs-6", s.navLink, {
                  [s.active]: location.pathname === "/conciliacion",
                })}
              >
                <MdBalance fontSize={20} /> Balance de cuentas
              </Link>
            </li>
            {/* ---------------------------- */}
            <hr hidden={userRole !== "superadmin"} />

            <a
              hidden={userRole !== "superadmin"}
              className={classnames("nav-link text-white fs-6", s.navLink, {
                [s.active]:
                  location.pathname === "/auditorias/saldosCvu" ||
                  location.pathname === "/auditorias/saldosCbu",
              })}
              data-bs-toggle="collapse"
              href="#auditorias"
              role="button"
              aria-expanded="false"
              aria-controls="auditorias"
            >
              {" "}
              <AiOutlineFileSearch style={{ fontSize: "18px" }} /> Auditorias
              <IoIosArrowDown />
            </a>

            <div className="row">
              <div className="col">
                <div className="collapse multi-collapse" id="auditorias">
                  <ul className={`pb-3 pt-3  ${s.dropdownMenu}`}>
                    <Link
                      className={classnames(
                        "mb-3 dropdown-item text-white ",
                        s.dropdownItem,
                        {
                          [s.active]:
                            location.pathname === "/auditorias/saldosCvu",
                        }
                      )}
                      style={{ fontSize: 15 }}
                      to="/auditorias/saldosCvu"
                    >
                      <RiMoneyDollarBoxLine /> Saldos CVU
                    </Link>
                  </ul>
                </div>
              </div>
            </div>

            {/* ---------------------------- */}
            {/* ---------------------------- */}
            <hr hidden={userRole !== "superadmin"} />

            <a
              hidden={userRole !== "superadmin"}
              className={classnames("nav-link text-white fs-6", s.navLink, {
                [s.active]:
                  location.pathname === "/reverso" ||
                  location.pathname === "/reverso",
              })}
              data-bs-toggle="collapse"
              href="#reverso"
              role="button"
              aria-expanded="false"
              aria-controls="reverso"
            >
              {" "}
              <GiReturnArrow style={{ fontSize: "18px" }} /> Reverso
              <IoIosArrowDown />
            </a>

            <div className="row">
              <div className="col">
                <div className="collapse multi-collapse" id="reverso">
                  <ul className={`pb-3 pt-3  ${s.dropdownMenu}`}>
                    <Link
                      className={classnames(
                        "mb-3 dropdown-item text-white ",
                        s.dropdownItem,
                        {
                          [s.active]: location.pathname === "reverso",
                        }
                      )}
                      style={{ fontSize: 15 }}
                      to="/reverso"
                    >
                      <RiMoneyDollarCircleFill style={{ fontSize: "17px" }} />{" "}
                      Botón de pago
                    </Link>

                    <Link
                      className={classnames(
                        "mb-3 dropdown-item text-white ",
                        s.dropdownItem,
                        {
                          [s.active]: location.pathname === "reverso",
                        }
                      )}
                      style={{ fontSize: 15 }}
                      to="/reverso-transfer"
                    >
                      <GrTransaction style={{ fontSize: "17px" }} />
                      Transferencias
                    </Link>
                  </ul>
                </div>
              </div>
            </div>

            {/* ---------------------------- */}
            <hr hidden={userRole === "atc" || userRole === "encAtc"} />
            <li
              className="nav-item"
              hidden={userRole === "atc" || userRole === "encAtc"}
            >
              <Link
                to="/alertas"
                className={classnames("nav-link text-white fs-6", s.navLink, {
                  [s.active]: location.pathname === "/alertas",
                })}
              >
                <TbAlertTriangle fontSize={20} /> Alertas
              </Link>
            </li>
            {/* ------------------------------------- */}
            <hr />
            <a
              hidden={userRole !== "superadmin"}
              className={classnames("nav-link text-white fs-6", s.navLink, {
                [s.active]: location.pathname === "/descargas",
              })}
              data-bs-toggle="collapse"
              href="#reportes"
              role="button"
              aria-expanded="false"
              aria-controls="reportes"
            >
              {" "}
              <AiOutlineFileSearch style={{ fontSize: "18px" }} /> Reportes
              <IoIosArrowDown />
            </a>

            <div className="row">
              <div className="col">
                <div className="collapse multi-collapse" id="reportes">
                  <ul className={`pb-3 pt-3  ${s.dropdownMenu}`}>
                    <Link
                      to="/descargas"
                      className={classnames(
                        "nav-link text-white fs-6",
                        s.navLink,
                        {
                          [s.active]: location.pathname === "/descargas",
                        }
                      )}
                    >
                      <MdDownload fontSize={20} />
                      Padron
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
            {/* ---------------------------- */}
            <hr hidden={userRole !== "superadmin"} />

            <a
              //hidden //ocultar a todos
              hidden={userRole === "atc"} 
              className={classnames("nav-link text-white fs-6", s.navLink, {
                [s.active]:
                  location.pathname === "/auxiliares/Qr" ||
                  location.pathname === "/auxiliares/Nfc",
              })}
              data-bs-toggle="collapse"
              href="#auxiliares"
              role="button"
              aria-expanded="false"
              aria-controls="auxiliares"
            >
              {" "}
              <LuList style={{ fontSize: "18px" }} /> Auxiliares
              <IoIosArrowDown />
            </a>

            <div className="row">
              <div className="col">
                <div className="collapse multi-collapse" id="auxiliares">
                  <ul className={`pb-3 pt-3  ${s.dropdownMenu}`}>
                    <Link
                      className={classnames(
                        "mb-3 dropdown-item text-white ",
                        s.dropdownItem,
                        {
                          [s.active]: location.pathname === "/auxiliares/Qr",
                        }
                      )}
                      style={{ fontSize: 15 }}
                      to="/auxiliares/Qr"
                    >
                      <LuNfc /> Listas QR / NFC
                    </Link>
                  </ul>
                </div>
              </div>
            </div>

            {/* ---------------------------- */}
          </ul>
        </div>
        <footer className={`mt-auto ${s.footer}`}>
          <div className={s.containerNav}>
            <div className={s.logo_user}>
              <div>
                <Link to={""} className={s.user}>
                  {/* <FaUserCircle
                    title="Usuario conectado"
                    style={{
                      fontSize: "25px",
                      marginRight: "5px",
                      color: "white",
                    }}
                  /> */}
                  <p
                    style={{
                      color: "white",
                      fontSize: "14px",
                      marginTop: "16px",
                    }}
                  >
                    {loggedInUser ? `${loggedInUser?.data_user?.name}` : ""}
                  </p>
                </Link>
              </div>
            </div>
            <div>
              <FaCog
                hidden={userRole === "atc" || userRole === "encAtc"}
                title="Configuración"
                onClick={() => navigate("/config")}
                style={{
                  fontSize: "22px",
                  marginRight: "8px",
                  color: "white",
                  cursor: "pointer",
                }}
              />
              <MdExitToApp
                onClick={handleLogout}
                title="Salir"
                style={{
                  fontSize: "25px",
                  marginLeft: "5px",
                  color: "white",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </footer>
      </nav>
      <div className={`${s.content} flex-grow-1`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Sidebar2;
