import React from "react";
import s from "../../styles/Paginado.module.css";

const Paginado = ({ data, pagina, setPagina }) => {
  const pages = [];
 //console.log(pagina)
  const calculatePages = () => {
    let startPage = Math.max(1, pagina - Math.floor(5 / 2));

    for (let i = startPage; i <= Math.min(data, startPage + 5 - 1); i++) {
      pages.push(i);
    }
    return pages;
  };

  const nextPage = () => {
   
    setPagina(pagina + 1);
  };

  const previousPage = () => {
    setPagina(pagina - 1);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        bottom: "0",
        width: "80%",
      }}
    >
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className="page-item">
            <a
              className={`page-link ${s.num}`}
              href="#"
              aria-label="Previous"
              onClick={previousPage}
              hidden={pagina <= 1}
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          {calculatePages().map((number) => (
            <li
              key={number}
              className={
                pagina === number
                  ? `${s.activePage} page-item`
                  : "page-item"
              }
            >
              <a
                onClick={() => setPagina(number)}
                className={`page-link ${s.num}`}
                href="#"
              >
                {number}
              </a>
            </li>
          ))}
          <li className="page-item">
            <a
              className={`page-link ${s.num}`}
              href="#"
              aria-label="Next"
              hidden={pagina >= data  }
              onClick={nextPage}
            >
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Paginado;

