import React from "react";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import {
  update_status_QR,
  get_passenger_QR,
  get_events_qr,
} from "../../redux/actions";
import Modal from "react-modal";
import styles from "../../styles/Users.module.css";
import Switch from "react-switch";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { CiCircleMore } from "react-icons/ci";
import { EventsQr } from "./EventsQr";

const QrDetail = ({ idUser, data, closeModal, setListQr }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [selectId, setSelectId] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [openListQr, setOpenListQr] = React.useState(false);
  useEffect(() => {}, [msg, checked]);
  const update = async () => {
    const body = {
      _id: selectId,
      status: checked,
      msg: msg,
    };

    if (msg === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Seleccione un motivo",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    const response = await dispatch(update_status_QR(body));
    response?.status === 200 &&
      Swal.fire({
        icon: "success",
        title: "Actualizado",
        text: response?.msg,
        showConfirmButton: false,
        timer: 3000,
      })
        .then(async () => {
          setMsg("");
          let res = await dispatch(get_passenger_QR(idUser));
          setListQr(res?.data);
          res?.status === 200 && setModal(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text:
              error?.response?.data?.msg || "Ocurrió un error al actualizar",
            showConfirmButton: false,
            timer: 3000,
          });
        });
  };

  const openModalUpdate = (id, status) => {
    if (status === "Activo") {
      setChecked(true);
    } else {
      setChecked(false);
    }
    setSelectId(id);
    setModal(true);
  };

  const checkedChange = (checked) => {
    setMsg("");
    setChecked(checked);
    // console.log(msg)
  };

  const eventsQr = (id) => {
    setOpenListQr(true);
    dispatch(get_events_qr(id));
  };
  return (
    <div className="container">
      <Link onClick={closeModal} className="me-2">
        <IoArrowBackCircleOutline
          style={{ fontSize: "30px", color: "orange" }}
        />
      </Link>
      {data?.length ? (
        <div className="card p-3 mb-2">
          <h5>Información Qr pasajero </h5>
          <table className="table mt-4">
            <thead>
              <tr>
                <th scope="col">QR</th>
                <th scope="col">Estado</th>
                <th scope="col">Fecha de emisión</th>
                <th scope="col">Fecha de vencimiento</th>
                <th scope="col">Actualizar</th>
                <th scope="col">Ver eventos</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item._id}>
                  <td>{item.qr}</td>
                  <td>{item.status}</td>
                  <td>{item.emision}</td>
                  <td>{item.vencimiento}</td>
                  <td>
                    <button
                      className="btn"
                      onClick={() => openModalUpdate(item._id, item.status)}
                    >
                      <FiEdit fontSize={22} />
                    </button>
                  </td>
                  <td>
                    <button className="btn" onClick={() => eventsQr(item._id)}>
                      <CiCircleMore fontSize={22} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="alert alert-info  mt-5">sin datos </p>
      )}
      <Modal
        isOpen={modal}
        className={styles.modal}
        overlayClassName={styles.Overlay} //fondo atras
        onRequestClose={() => setModal(false)}
        contentLabel="Detalle Qr del usuario"
      >
        <div className="d-flex justify-content-center mt-5">
          <Link onClick={() => setModal(false)} className="me-2">
            <IoArrowBackCircleOutline
              style={{ fontSize: "30px", color: "orange" }}
            />
          </Link>

          <div className="card p-2 " style={{ width: "60%" }}>
            <h5 className="text-center">Actualizar Estado</h5>
            <div className="d-flex align-items-center ">
              <label htmlFor="" className="me-4">
                Estado{" "}
              </label>
              <Switch
                onChange={(checked) => checkedChange(checked)}
                checked={checked}
              />
            </div>
            <div className="d-flex align-items-center py-3">
              <label className="me-4" htmlFor="">
                Motivo
              </label>
              <select
                value={msg}
                className="form-control"
                onChange={(e) => setMsg(e.target.value)}
              >
                <option value="" hidden>
                  Seleccionar
                </option>
                <option value="Qr extraviado" disabled={checked}>
                  Qr extraviado
                </option>
                <option value="Bloqueado por uso indebido" disabled={checked}>
                  Bloqueado por uso indebido
                </option>
                <option value="Solicitud de reactivar" disabled={!checked}>
                  Solicitud de reactivar
                </option>
                <option value="Solicitud de Bloquear" disabled={checked}>
                  Solicitud de Bloquear
                </option>
              </select>
              <button
                onClick={update}
                className="btn p-2 btn-sm ms-2 text-white"
                style={{ backgroundColor: "var(--naranja)" }}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Lista eventos qr */}
      <Modal
        isOpen={openListQr}
        className={styles.modal}
        overlayClassName={styles.Overlay} //fondo atras
        onRequestClose={() => setOpenListQr(false)}
        contentLabel="Eventos Qr del usuario"
      >
        <EventsQr closeModalQr={setOpenListQr}/>
      </Modal>
    </div>
  );
};

export default QrDetail;
