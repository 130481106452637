import axios from "axios";
const REACT_URL_BACK = process.env.REACT_APP_URL;
const REACT_APP_URLVAL = process.env.REACT_APP_URLVAL;
const REACT_SECRET = process.env.REACT_APP_SECRET;
const REACT_REPORT=process.env.REACT_APP_REP
export const TYPES = {
  ERROR_MENSAJE: "ERROR_MENSAJE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOG_OUT: "LOG_OUT",
  GET_USERS: "GET_USERS",
  SEARCH_USER: "SEARCH_USER",
  SEARCH_USER_DETALLE: "SEARCH_USER_DETALLE",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  SEARCH_USER_BY_VALIDATION: "SEARCH_USER_BY_VALIDATION",
  SEARCH_USER_BY_NAME: "SEARCH_USER_BY_NAME",
  FILTER_BY_ESTADO: "FILTER_BY_ESTADO",
  GET_ADMIN: "GET_ADMIN",
  SEARCH_ADMIN_BY_NAME: "SEARCH_ADMIN_BY_NAME",
  SEARCH_ADMIN_BY_DNI: "SEARCH_ADMIN_BY_DNI",
  SEARCH_ADMIN_BY_STATE: "SEARCH_ADMIN_BY_STATE",
  GET_TRANSACCIONES: "GET_TRANSACCIONES",
  GET_TRANS_BY_CVU_LABEL: "GET_TRANS_BY_CVU_LABEL",
  FILTER_TRANS_BY_ESTADO: "FILTER_TRANS_BY_ESTADO",
  FILTER_TRANS_BY_CODIGO: "FILTER_TRANS_BY_CODIGO",
  GET_ACCOUNT: "GET_ACCOUNT",
  GET_MOVIMIENTOS: "GET_MOVIMIENTOS",
  CREATE_TRANSPORT: "CREATE_TRASPORT",
  CREATE_SHOP: "CREATE_SHOP",
  FOTOS: "FOTOS",
  LIST_BUSSINES: "LIST_BUSSINES",
  LIST_SHOPS: "LIST_SHOPS",
  LIST_TAXS: "LIST_TAXS",
  LIST_PROVINCES: "LIST_PROVINCES",
  LIST_MUNICIPIOS: "LIST_MUNICIPIOS",
  FILTRO_TRANSPORT: "FILTRO_TRANSPORT",
  FILTRO_TRANS: "FILTRO_TRANS",
  LIST_TYPES: "LIST_TYPES",
  LIST_ASOCIADOS: "LIST_ASOCIADOS",
  TRANSACCION_DETALLE: "TRANSACCION_DETALLE",
  FILTER_BUSSINESS: "FILTER_BUSSINESS",
  FILTER_SHOP: "FILTER_SHOP",
  MOV_USER: "MOV_USER",
  SEARCH_ASOCIADO_BY_NAME: "SEARCH_ASOCIADO_BY_NAME",
  SEARCH_ASOCIADO_BY_DNI: "SEARCH_ASOCIADO_BY_DNI",
  FILTER_ASOCIADO_BY_ESTADO: "FILTER_ASOCIADO_BY_ESTADO",
  CLEAR_MOV_USER: "CLEAR_MOV_USER",
  CLEAR_DETAIL: "CLEAR_DETAIL",
  ASOCIADO_DETAIL: "ASOCIADO_DETAIL",
  BUSINESS_DETALLE: "BUSINESS_DETALLE",
  MOVIMENTS_BUSSINE: "MOVIMENTS_BUSSINE",
  SHOP_DETAIL: "SHOP_DETAIL",
  CLAEAR_PHOTOS: "CLEAR_PHOTOS",
  DETAIL_CONCILIATION: "DETAIL_CONCILIATION",
  CLAEAR_DETAIL_CONCILIACION: "CLAEAR_DETAIL_CONCILIACION",
  ACCOUNT_BALANCE: "ACCOUNT_BALANCE",
  ACCOUNT_MOVIMENTS: "ACCOUNT_MOVIMENTS",
  ONE_TRANSFER_USER:"ONE_TRANSFER_USER",
  ALERT_ACTIVE :"ALERT_ACTIVE",
  ALERT_ATENDIDAS:"ALERT_ATENDIDAS",
  DATA_PDF:"DATA_PDF",
  REPORT_SALDO_CVU:"REPORT_SALDO_CVU",
  FILTER_BY_PLATFORM:"FILTER_BY_PLATFORM",
  SEARCH_ALERT_BY_DNI:"SEARCH_ALERT_BY_DNI",
  LIST_PASSENGER_DNI:"LIST_PASSENGER_DNI",
  LIST_EVENTS_QR:"LIST_EVENTS_QR",
  LIST_EVENTS_NFC:"LIST_EVENTS_NFC"

};
// Obtener el token almacenado en el local storage
var user = localStorage.getItem("user-log");
const token = JSON.parse(user)?.token; // guardo solo el token


export function LoginSuccess(data) {
  try {
    return async function (dispatch) {
      window.localStorage.setItem("user-log", JSON.stringify(data));


      dispatch({
        type: TYPES.LOGIN_SUCCESS,
        payload: data,
      });
    };
  } catch (error) {
    console.log(error.message);
  }
}
export function LogOut() {
  return async function (dispatch) {
    dispatch({
      type: TYPES.LOG_OUT,
    });
  };
}


export function getUsers(page, estado) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    const body = {
      page: page,
      filter: estado,
    };
    // Imprimir el token inmediatamente después de obtenerlo
    if (!token) {
      return dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: "Token not found",
      });
    }

    try {
      // Imprimir el token antes de realizar la solicitud
      const response = await axios.post(
        `${REACT_URL_BACK}v1/List/Users`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );

      dispatch({
        type: TYPES.GET_USERS,
        payload: response.data.data,
        payload2: response.data.total,
      });
    } catch (error) {
      //console.error("Error Response:", error.response);
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}

// buscar  DNI
export const search_User = (params) => {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;

  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/List/UserDNI`,
        params,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );

      dispatch({
        type: TYPES.SEARCH_USER,
        payload: response.data?.data,
        payload2: response.data?.total,
      });

      return response.data;
    } catch (error) {
      console.error("Error al buscar usuarios:", error.message);
      return error;
    }
  };
};


// buscar  DNI detalle
export const search_User_detalle = (_id) => {
  //console.log(cuit)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
    //console.log(_id)
    const response = await axios.post(`${REACT_URL_BACK}v1/List/UserDNI`, _id, {
      headers: {
        "SERVICIOS-URBANOS-TOKEN": token,
      },
    });

    try {
      dispatch({
        type: TYPES.SEARCH_USER_DETALLE,
        payload: response.data,
      });
      //console.log(response.data)
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  };
};
// buscar por nombre

export function search_by_name(userSearch) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    //console.log(userSearch)
    const response = await axios.post(
      `${REACT_URL_BACK}v1/List/UserDNI`,
      userSearch,
      {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      }
    );
    //console.log(response.data.data)
    try {
      dispatch({
        type: TYPES.SEARCH_USER_BY_NAME,
        payload: response.data?.data,
        payload2: response.data?.total,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}

// CREAR USUARIO
export const create_user = (user) => {
  const login = localStorage.getItem("user-log");
  const token = JSON.parse(login)?.token;
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}Auth/addUser?user=${user.user}&pass=${user.password}&name=${user.name}&dni=${user.dni}`,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);

      dispatch({
        type: TYPES.CREATE_USER,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
};
//MODIFICAR
export const Update_user = (dni, datos) => {
  //console.log(datos)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
    try {
      const data = await axios.put(`${REACT_URL_BACK}v1/User/UpdUser`, datos, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });

      return data.data;
    } catch (error) {
      return error.response;
    }
  };
};
// adminISTRADORES
export function getAdmin() {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    // Imprimir el token inmediatamente después de obtenerlo

    if (!token) {
      return dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: "Token not found",
      });
    }
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/List/Admin`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data.data);

      dispatch({
        type: TYPES.GET_ADMIN,
        payload: response?.data?.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
// filtros admin
export function search_Admin_by_name(data) {
  return async function (dispatch) {
    //e.log(data);
    try {
      dispatch({
        type: TYPES.SEARCH_ADMIN_BY_NAME,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
export function search_Admin_by_dni(data) {
  return async function (dispatch) {
    //console.log(data)
    try {
      dispatch({
        type: TYPES.SEARCH_ADMIN_BY_DNI,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
//  estado
export function search_admin_state(data) {
  return async function (dispatch) {
    //console.log(data);
    try {
      dispatch({
        type: TYPES.SEARCH_ADMIN_BY_STATE,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
export const create_admin = (data) => {
  // console.log(token)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
    try {
      const response = await axios.post(`${REACT_URL_BACK}addAdmin`, data, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      // console.log("create admin",response.data);
      dispatch({
        type: TYPES.CREATE_USER,
        payload: response.data.data,
      });
      return response.data;
    } catch (error) {
      //console.log("error", error);

      return error.response.data;
    }
  };
};
//*------- Transacciones bind---------------

export function getTransacciones(body) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    // Imprimir el token inmediatamente después de obtenerlo

    if (!token) {
      return dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: "Token not found",
      });
    }
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/List/Transfer`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log("transacciones biend:",response.data.msg);

      dispatch({
        type: TYPES.GET_TRANSACCIONES,
        payload: response.data.msg,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  };
}

export function Filter_trans_codigo(data) {
  return async function (dispatch) {
    // console.log(data);
    try {
      const array = [];
      const response = await axios.post(
        `${REACT_URL_BACK}List/TransferById`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );

      array.push(response?.data?.msg);
      dispatch({
        type: TYPES.FILTER_TRANS_BY_CODIGO,
        payload: array,
      });

      return response.data;
    } catch (error) {
      //console.log(error)
      // return error
    }
  };
}

// DASHBOARD DATA
export function getDataAccount() {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.get(`${REACT_URL_BACK}Banck/Account`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      // console.log(response.data.data);

      dispatch({
        type: TYPES.GET_ACCOUNT,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
//TRANSFERENCIAS
export function getMovimientos() {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.get(`${REACT_URL_BACK}Banck/ListMov`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data.data);

      dispatch({
        type: TYPES.GET_MOVIMIENTOS,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
// !----------------REGISTRO RED SUSA-----------------!
export function Create_tranport_business(data) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Business/AddBusiness`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);

      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}
export function Create_shop(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Shops/AddShops`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);
      return response.data;
    } catch (error) {
      //console.log(error.response);
      return error?.response?.data;
    }
  };
}

export function Create_tax(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Tax/AddTax`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}

//?CAMBIAR ESTADO ADMIN
export function adminUpdate(data) {
  //console.log(data)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.put(`${REACT_URL_BACK}StateAdmin`, data, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data)
      dispatch(getAdmin()); // Actualiza la lista de administradores después del cambio

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
}

//?CAMBIAR ESTADO USER
export function userUpdateState(data) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.put(
        `${REACT_URL_BACK}v1/User/StateUser`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
   

      return response?.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}

//?BORRADO LOGICO USUARIO
export function deleteUserLogic(dni) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/User/DelUser`,
        { dni: dni },
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      // console.log(response.data)
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
}

//?fotos
// export function searchPhotos(cuit) {
//   return async function (dispatch) {
//     // console.log(cuit)
//     const user = localStorage.getItem("user-log");
//     const token = JSON.parse(user)?.token;
//     try {
//       const response = await axios.post(`${REACT_APP_URLVAL}`, cuit, {
//         headers: {
//           "SERVICIOS-URBANOS-TOKEN": token,
//         },
//       });
//       dispatch({
//         type: TYPES.FOTOS,
//         payload: response?.data.data,
//       });
//       //console.log(response.data)
//       return response.data;
//     } catch (error) {
//       return error?.response?.data;
//     }
//   };
// }

//?----------PROVINCIASS----------//
export const getProvincias = () => {
  return async (dispatch) => {
    const result = await axios.get(
      "https://apis.datos.gob.ar/georef/api/provincias?campos=id,nombre"
    );
    dispatch({ type: TYPES.LIST_PROVINCES, payload: result?.data?.provincias });
  };
};
export const getMunicipios = (id) => {
  return async (dispatch) => {
    const result = await axios.get(
      `https://apis.datos.gob.ar/georef/api/municipios?provincia=${id}&campos=id,nombre&max=500`
    );

    // Ordenar alfabéticamente los municipios por su nombre
    const sortedMunicipios = result?.data?.municipios.sort((a, b) =>
      a.nombre.localeCompare(b.nombre)
    );

    dispatch({
      type: TYPES.LIST_MUNICIPIOS,
      payload: sortedMunicipios,
    });
  };
};

//?-------------------LISTAS-------------------
export function getBussinesList() {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/List/Business`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data)
      dispatch({
        type: TYPES.LIST_BUSSINES,
        payload: response?.data?.data,
      });
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  };
}

export function getShopsList() {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/List/Shops`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      // console.log(response.data.msg)
      dispatch({
        type: TYPES.LIST_SHOPS,
        payload: response.data.msg,
      });
      return response.data;
    } catch (error) {
      return error?.response?.data;
    }
  };
}
export function getTaxsList(data) {
  return async function (dispatch) {
    //console.log(data)
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/List/Tax`, data, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      dispatch({
        type: TYPES.LIST_TAXS,
        payload: response.data.msg,
      });
      //console.log(response.data.msg)
      return response.data;
    } catch (error) {
      //console.log(error);
      return error.response;
    }
  };
}
//? -----------------UPDATE STATUS--------------------
export function updateBussine(data) {
  //console.log(data)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.put(
        `${REACT_URL_BACK}v1/Business/StateBusiness`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data)
      dispatch(getBussinesList());
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
}
export function updateShop(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.put(
        `${REACT_URL_BACK}v1/Shops/StateShops`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data)
      dispatch(getShopsList());
      return response.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  };
}

export function updateTax(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.put(
        `${REACT_URL_BACK}v1/Tax/StateTax`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      // console.log(response.data)
      dispatch(getTaxsList());
      return response.data;
    } catch (error) {
      // console.log(error);
      return error?.response?.data;
    }
  };
}
// *------------------- asignar cvu-----------------------

export function asignCvu(cuit) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}AsingCVU`, cuit, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data)

      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}
//  CVU COMERCIO Y EMPRESA

export function asignCvuEnty(cuit, clientId) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    let body = {
      cuit: cuit,
      clientId: clientId,
    };
    //console.log(body)
    try {
      const response = await axios.post(`${REACT_URL_BACK}AsingCVUEnty`, body, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data)

      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}

export function getTypesUser() {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/List/Benefits`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data)
      dispatch({
        type: TYPES.LIST_TYPES,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}

//*-------FILTROS TRANSPORTE----------

export function filterTrasporte(filtro) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.FILTRO_TRANSPORT,
      payload: filtro,
    });
  };
}

// *----------------RECUPERAR CONTRASEÑA ADMIN--------------
export function sendCodePass(email) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${REACT_URL_BACK}SendCodePass`, email);
      //console.log(response.data)
      return response.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  };
}

export function recoverPass(data) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${REACT_URL_BACK}InputCodePass`, data);
      // console.log(response.data)
      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}

// * --------FILTROS TRANSACCIONES -------

export function filterTrans(filtro) {
  //console.log(filtro)
  return async function (dispatch) {
    dispatch({
      type: TYPES.FILTRO_TRANS,
      payload: filtro,
    });
  };
}

export const transaccion_detalle = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TYPES.TRANSACCION_DETALLE,
        payload: id,
      });
    } catch (error) {
      // console.log(error?.response)
    }
  };
};
export function clearDetail() {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLEAR_DETAIL,
    });
  };
}
// * --------FILTROS EMPRESA TRANSPORTE -------

export function filterBussiness(filtro) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.FILTER_BUSSINESS,
      payload: filtro,
    });
  };
}
// * --------FILTROS SHOP-------
export function filterShop(filtro) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.FILTER_SHOP,
      payload: filtro,
    });
  };
}
// ?----------ASOCIADOS ---------------

export function getAsociados(page) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    if (!token) {
      //console.log("No hay token");
    }
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/User/List/All/Associated`,
        {
          page: page,
        },
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(page)
      dispatch({
        type: TYPES.LIST_ASOCIADOS,
        payload: response.data.msg,
        payload2: response.data.total,
      });
    } catch (error) {
      // console.error("Error Response:", error.response);
      return error.response;
    }
  };
}
// * --------FILTROS ASOCIADOS-------
export function search_User_asociado(search) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    const response = await axios.post(
      `${REACT_URL_BACK}v1/User/List/Associate/Search/Console`,
      { dni: search, page: 1 },
      {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      }
    );
    dispatch({
      type: TYPES.SEARCH_ASOCIADO_BY_DNI,
      payload: response.data.data,
      payload2: response.data?.total,
    });
  };
}
export function search_asociado_detail(id) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    const response = await axios.post(
      `${REACT_URL_BACK}v1/User/List/Associate/Search/Console`,
      id,
      {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      }
    );
//console.log(response.data)
    dispatch({
      type: TYPES.ASOCIADO_DETAIL,
      payload: response.data.data,
      payload2: response.data?.total,
    });
  };
}
export function search_User_asociado_status(search) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.FILTER_ASOCIADO_BY_ESTADO,
      payload: search,
    });
  };
}
export function search_User_asociado_name(search) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    const response = await axios.post(
      `${REACT_URL_BACK}v1/User/List/Associate/Search/Console`,
      search,
      {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      }
    );

    dispatch({
      type: TYPES.SEARCH_ASOCIADO_BY_NAME,
      payload: response.data.data,
      payload2: response.data?.total,
    });
  };
}
// ?----------DETALLE MOVIMIENTOS---------------

export function getMovUser(body) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      //console.log(body)
      const response = await axios.post(
        `${REACT_URL_BACK}v1/User/ListMovUser`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);

      dispatch({
        type: TYPES.MOV_USER,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response.data;
    }
  };
}
export function clearMovUser() {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLEAR_MOV_USER,
    });
  };
}
//*------RECARGAR COMERCIO--------
export function creditShop(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Shops/Credit`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response.data;
    }
  };
}
export function shopFiles(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Shops/Search/File`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );

      //console.log("Lista comprobantes",response.data);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
//*-----------------ROLES Y PERMISOS-----------------
export function ListaPermisos(obs) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Permission/List`,
        obs,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );

      return response.data;
    } catch (error) {
      // console.log(error.response.data);
      return error?.response;
    }
  };
}
export function ListaRoles(rol) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/Role/List`, rol, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });

      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
export function AddRol(body) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/Role/Add`, body, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      await dispatch(ListaRoles(""));

      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
//ENROLLAMENT PERSON
export function ActiveBiometria(body) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/User/Check`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );

      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
// DETALLE EMPRESA TRANSPORTE
export function search_bussine_detail(id) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/List/OneBusiness`,
        id,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data)
      // return response.data;
      dispatch({
        type: TYPES.BUSINESS_DETALLE,
        payload: response.data.msg,
      });
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
export function Retiro_dinero_empresa(body) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Business/MoneyWithdrawal`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
            SECRET: REACT_SECRET,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}

export function exist_cvu_bussine(body) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Business/Exist`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
            SECRET: REACT_SECRET,
          },
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}

export function bussine_transactions(body) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Business/ListMov`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);
      dispatch({
        type: TYPES.MOVIMENTS_BUSSINE,
        payload: response.data?.msg,
        payload2: response.data?.total,
      });
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}

export function add_cvu_bussines(body) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Business/Accounts`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);

      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}

// detalle comercio
export function search_shop_detail(id) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/List/OneShops`,
        id,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data)
      // return response.data;
      dispatch({
        type: TYPES.SHOP_DETAIL,
        payload: response.data.msg,
      });
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}

// otos facetec
export function search_user_facetec(dni) {
  return async function (dispatch) {
    try {
      const res = await axios.post(
        `${REACT_APP_URLVAL}V1/Facetec/Info`,
        dni,
        {
          headers: {
            SECRET: REACT_SECRET,
          },
        }
      );
     // console.log(res?.data);
      dispatch({
        type: TYPES.FOTOS,
        payload: res?.data?.msg?.images,
      });
    } catch (error) {
      //console.log(error.response.data);
   
      return error?.response;
    }
  };
}

export function clearPhotos() {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLAEAR_PHOTOS,
    });
  };
}

//CONCILIAR
export function conciliar_transfer(id) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(`${REACT_URL_BACK}conciliar`, id, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      dispatch({
        type: TYPES.DETAIL_CONCILIATION,
        payload: response?.data?.msg,
      });
      //console.log(response?.data);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      // return error?.response;
    }
  };
}
export function conciliar_excec(data) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(`${REACT_URL_BACK}EXCEC`, data, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });

      await dispatch(conciliar_transfer({ id: data.id }));

      //console.log(response?.data);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
export function clearConcilicionDetail() {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLAEAR_DETAIL_CONCILIACION,
    });
  };
}

export function get_account_balance() {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.get(`${REACT_URL_BACK}Accounts`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      dispatch({
        type: TYPES.ACCOUNT_BALANCE,
        payload: response?.data,
      });
      // console.log(response?.data);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
export function get_moviments_accounts(body) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;

  return async function (dispatch) {
    try {
      const response = await axios.post(`${REACT_URL_BACK}Moviment`, body, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      dispatch({
        type: TYPES.ACCOUNT_MOVIMENTS,
        payload: response?.data.msg,
        payload2: response?.data?.total,
      });

      return response.data.msg;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}

export const detalle_tranfer_user = (id) => {
  console.log(id)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
    try {
      const res = await axios.post(`${REACT_URL_BACK}v1/User/ListMovUser/OneTransfer`,{origin_id:id}, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      dispatch({
        type:TYPES.ONE_TRANSFER_USER,
        payload:res.data?.lista
      })
     //console.log(res.data)
     
    } catch (error) {
      return error.response;
    }
  };
};

// Alertas
export const get_alert_active = (page) => {

  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
    try {
      const res = await axios.post(`${REACT_URL_BACK}Alerts/Activas
      `,page, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
     
    // console.log(res.data)
     dispatch({
      type:TYPES.ALERT_ACTIVE,
      payload:res.data.msg,
      payload2:res.data.total
     })
    } catch (error) {
      return error.response;
    }
  };
};
export const get_alert_atendidas = (page) => {

  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
    try {
      const res = await axios.post(`${REACT_URL_BACK}Alerts/Served
      `,page, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
     
     //console.log(res.data)
     dispatch({
      type:TYPES.ALERT_ATENDIDAS,
      payload:res.data.msg,
      payload2:res.data.total

     })
    } catch (error) {
      return error.response;
    }
  };
};
export const get_alert_state = (body) => {
  //console.log(body)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
    try {
      const res = await axios.post(`${REACT_URL_BACK}Alerts/States
      `,body, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
    
    //  console.log(res.data)
     return res.data
    } catch (error) {
      return error.response;
    }
  };
};

export const generatePDF = (body) => {
 // console.log(body)
  
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
  
    try {
      const res = await axios.post(`${REACT_URL_BACK}Legajo/PDF
      `,body, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
     // console.log(res.data)
     dispatch({
     type:TYPES.DATA_PDF,
     payload:res.data

     })
     return res.data
    } catch (error) {
      //console.log(error.response)
      return error.response;
    }
  };
};


export const reporte_saldos_cvu = (date) => {
  //console.log(date)
   
   const user = localStorage.getItem("user-log");
   const token = JSON.parse(user)?.token;
   return async (dispatch) => {
   
     try {
       const res = await axios.post(`${REACT_URL_BACK}Report/Photo/Users`,date, {
         headers: {
           "SERVICIOS-URBANOS-TOKEN": token,
         },
       });
       console.log(res.data)
      dispatch({
      type:TYPES.REPORT_SALDO_CVU,
      payload:res.data,
      payload2:res.data?.total
 
      })
      return res.data
     } catch (error) {
       console.log(error)
       return error.response;
     }
   };
 }

 export const reporte_fotos = (date) => {
 // console.log(date)
   
   const user = localStorage.getItem("user-log");
   const token = JSON.parse(user)?.token;
   return async (dispatch) => {
   
     try {
       const res = await axios.post(`${REACT_URL_BACK}Report/Photo/Rec`,date, {
         headers: {
           "SERVICIOS-URBANOS-TOKEN": token,
         },
       });
     // console.log(res.data)
     
      return res.data
     } catch (error) {
       console.log(error)
       return error.response;
     }
   };
 };
//* -------------CONFIGURACIÓN-------------
export const state_Tranfer = (body) => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/Config/Transfer`,body,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};

export const state_Extraction = (body) => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/Config/Extraction`,body,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};
export const state_BtnSimple = (body) => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/Config/BTN`,body,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};
export const get_state_config = () => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/Config/List`,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};







export const revertir = (id) => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/Reverse/BTN`,id,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
     // console.log(response.data)
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};
export const get_list_Onboarding = (id) => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/User/List/ATC/Onboarding`,id,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
     // console.log(response.data)
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};
export const update_state_Onboarding = (body) => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/User/Change/ATC/Onboarding`,body,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
     // console.log(response.data)
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};


export const change_postal_code = (body) => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/User/Change/PostalCode`,body,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
     // console.log(response.data)
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};

export const Download_report_user = (date) => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}Report/Photo/Download`,date,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
     // console.log(response.data)
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};

export const revertir_transfer= (id) => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/Reverse/Transfer/Sent`,id,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
     // console.log(response.data)
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};


export const activar_user= (body) => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/User/Change/Active`,body,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
      //console.log(response.data)
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};


export const   get_provinces= () => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/List/Provinces`,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
      //console.log(response.data)
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};


export const BCRAFunction= (body) => {
 // console.log(REACT_REPORT)
  return async (dispatch) => {
  
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_REPORT}BCRA/Etapa2/New`,body,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
   
    // console.log("padron",response)
      return response;
    } catch (error) {
      console.error("Error :", error);
      return error?.response?.data;
    }
  };
};

export const BCRA_down= (body) => {
  // console.log(REACT_REPORT)
   return async (dispatch) => {
   
     const user = localStorage.getItem("user-log");
     const token = JSON.parse(user)?.token;
     try {
       const response = await axios.post(`${REACT_REPORT}BCRA/Etapa2/Down
       `,body,{
         headers:{
 
           "SERVICIOS-URBANOS-TOKEN": token,
          }
       })
      // console.log("down",response)
       return response;
     } catch (error) {
       console.error("Error :", error);
       return error?.response?.data;
     }
   };
 };


export const BCRA_modif= (body) => {
  // console.log(REACT_REPORT)
   return async (dispatch) => {
   
     const user = localStorage.getItem("user-log");
     const token = JSON.parse(user)?.token;
     try {
       const response = await axios.post(`${REACT_REPORT}BCRA/Etapa2/modif
       `,body,{
         headers:{
 
           "SERVICIOS-URBANOS-TOKEN": token,
          }
       })
      //console.log("modif",response)
       return response;
     } catch (error) {
       console.error("Error :", error);
       return error?.response?.data;
     }
   };
 };
 

export const SearchAlertbyDni= (dni) => {
  // console.log(REACT_REPORT)
   return async (dispatch) => {
   
     const user = localStorage.getItem("user-log");
     const token = JSON.parse(user)?.token;
     try {
       const response = await axios.post(`${REACT_URL_BACK}Alerts/SearchDni
       `,dni,{
         headers:{
 
           "SERVICIOS-URBANOS-TOKEN": token,
          }
       })
       //console.log(response.data)
       dispatch({
        type: TYPES.SEARCH_ALERT_BY_DNI,
        payload: response.data,
      });
     
     } catch (error) {
       console.error("Error :", error);
       dispatch({
        type: TYPES.SEARCH_ALERT_BY_DNI,
        payload: [],
      });
       return error?.response?.data;
     }
   };
 };

 export const filterByPlatform= (platform) => {
  // console.log(REACT_REPORT)
   return async (dispatch) => {
  //console.log(platform)
     const user = localStorage.getItem("user-log");
     const token = JSON.parse(user)?.token;
     try {
       const response = await axios.post(`${REACT_URL_BACK}v1/List/Users/Platform
       `,platform,{
         headers:{
 
           "SERVICIOS-URBANOS-TOKEN": token,
          }
       })
       //console.log(response.data)
       dispatch({
        type: TYPES.FILTER_BY_PLATFORM,
        payload: response.data?.data,
        payload2: response.data?.total,
      });
     
     } catch (error) {
       console.error("Error :", error);
       return error?.response?.data;
     }
   };
 };


 export const activarTransferenciasUser = (body) => {
  // console.log(REACT_REPORT)
   return async (dispatch) => {
  //console.log(platform)
     const user = localStorage.getItem("user-log");
     const token = JSON.parse(user)?.token;
     try {
       const response = await axios.post(`${REACT_URL_BACK}v1/List/Perfil/State
       `,body,{
         headers:{
 
           "SERVICIOS-URBANOS-TOKEN": token,
          }
       })
       //console.log(response.data)
      return response.data;
     
     } catch (error) {
       console.error("Error :", error);
       return error?.response?.data;
     }
   };
 };

//  ------ auxiliares ------

export const get_passenger_dni = (dni) => {

   return async (dispatch) => {

     const user = localStorage.getItem("user-log");
     const token = JSON.parse(user)?.token;
     try {
       const response = await axios.get(`${REACT_URL_BACK}v1/pagos-auxiliares/get-passenger-dni?dni=${dni}
       `,{
         headers:{
 
           "SERVICIOS-URBANOS-TOKEN": token,
          }
       })
      
      return response.data;
     
     } catch (error) {
       console.error("Error :", error);
       return error?.response?.data;
     }
   };
 };
 export const get_passenger_QR = (id) => {

  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/pagos-auxiliares/qr?_id=${id}
      `,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
   
     return response.data;
    
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};

export const update_status_QR = (body) => {

  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.put(`${REACT_URL_BACK}v1/pagos-auxiliares/qr?_id=${body._id}
      `,body,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
     //console.log(response.data)
     return response?.data;
    
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};


export const get_passenger_NFC = (id) => {

  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/pagos-auxiliares/nfc?_id=${id}
      `,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
   
     return response.data;
    
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};

export const update_status_NFC = (body) => {

  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.put(`${REACT_URL_BACK}v1/pagos-auxiliares/nfc
      `,body,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
   
     return response.data;
    
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};

export const revalidar_nosis_user= (body) => {
  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {

      const response = await axios.post(`${REACT_URL_BACK}v1/User/ReValidate/Nosis`,body,{
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,

         }
      })
     // console.log(response.data)
      return response?.data;
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};


export const get_events_qr= (id) => {

  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/pagos-auxiliares/qr-events?_id=${id}`,
      {
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
   // console.log(response.data.data)
     dispatch({
      type: TYPES.LIST_EVENTS_QR,
      payload: response?.data?.data
     })
    
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};


export const get_events_nfc= (id) => {

  return async (dispatch) => {

    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/pagos-auxiliares/nfc-events?_id=${id}`,
      {
        headers:{

          "SERVICIOS-URBANOS-TOKEN": token,
         }
      })
    // console.log(response.data.data)
     dispatch({
      type: TYPES.LIST_EVENTS_NFC,
      payload: response?.data?.data
     })
    
    } catch (error) {
     // console.error("Error :", error);
      return error?.response?.data;
    }
  };
};