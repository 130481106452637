import React from "react";
import s from "../styles/registro.module.css";
import logo from "../img/susa-logo.png";
import { useState } from "react";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { create_admin, create_user, getAdmin } from "../redux/actions";
import { useNavigate } from "react-router-dom";
import { TbArrowBack } from "react-icons/tb";
import { IoArrowBackCircleOutline, IoClose } from "react-icons/io5";
const Registrer = () => {
  const [seePassword, setSeePassword] = useState(false);
  const [usuario, setUsuario] = useState({
    name: "",
    dni: "",
    user: "",
    password: "",
    email:"",
    role:""
  });
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ selectedRol,setSelectedRol]= useState("")
  const Registrer =async (e) => {
    e.preventDefault();
   //console.log(usuario);
    if (!usuario.name|| !usuario.password || !usuario.user || !usuario.dni) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Complete todos los campos',
        });
      } else {
        let userSend={
          user:usuario.user,
          pass:usuario.password.trim(),
          name:usuario.name,
          email:usuario.email?.toLowerCase(),
          dni: parseInt(usuario.dni),
          role:selectedRol
        }
       const response= await  dispatch(create_admin(userSend))
       //console.log(userSend)
       if(response.status===200){
         Swal.fire({
           icon: 'success',
          
           text: 'Usuario registrado correctamente',
         });
         setTimeout(() => {
           navigate("/operadores")
          dispatch(getAdmin())
         }, 500);
       }
       else{
        Swal.fire({
          icon: 'error',
          title: 'error',
          text: response?.msg || "Ocurrió un error ",
        });
       }
    }
  };
  const HandleChangue = (e) => {
    setUsuario({ ...usuario, [e.target.name]: e.target.value });
  };
  return (
    <>
     
      <IoArrowBackCircleOutline title="Volver" style={{fontSize:"30px", color:"orange", cursor:"pointer" }}
      onClick={()=> navigate("/operadores")}
      />

    
    <div className={s.conteinerRegistro}>
      <form className={s.conteinerForm} onSubmit={Registrer}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "30%", marginBottom: "10px", padding: "5px" }}
          />
        </div>
      <p className="text-center mb-2 pb-3 fs-6" >Formulario de Registro Operador de Consola </p>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Nombre y Apellido
          </label>
          <input
            value={usuario?.name}
            name="name"
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            onChange={HandleChangue}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            DNI
          </label>
          <input
            value={usuario?.dni}
            name="dni"
            type="number"
            min={0}
            className="form-control"
            id="exampleFormControlInput1"
            onChange={HandleChangue}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Correo
          </label>
          <input
            value={usuario?.email}
            name="email"
            className="form-control"
            id="exampleFormControlInput1"
            onChange={HandleChangue}
          />
        </div>
        <div className="row">
          <div className="col-6">
        
          <input
            value={usuario?.user}
            name="user"
            type="text"
            placeholder="Nombre de usuario"
            className="form-control"
            id="exampleFormControlInput1"
            onChange={HandleChangue}
          />

          </div>
           <div className="col-6">
         
          <select name="" id="" className="form-control" 
             onChange={(e) =>setSelectedRol(e.target.value)} value={selectedRol}>
          <option hidden value="">Seleccionar rol</option>
            <option value="atc">Atención al cliente</option>
            <option value="encAtc">Encargado ATC</option>
            <option value="admin">Admin</option>
            <option value="superadmin">SuperAdmin</option>
          </select>
          </div>
        </div>
        <div className="mb-3" style={{ position: "relative" }}>
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Contraseña
          </label>
          <input
            value={usuario?.password}
            name="password"
            type={seePassword ? "text" : "password"}
            className="form-control"
            id="exampleFormControlInput1"
            onChange={HandleChangue}
          />
        <div
            className={s.ojito}
            onClick={() => {
              setSeePassword(!seePassword);
            }}
          >
            {seePassword ? <BsEyeSlash /> : <BsEye />}
          </div>
        </div>
       
        <button className={`btn ${s.btnRegistro}`}>Registrar</button>
      </form>
    </div>
    </>
  );
};

export default Registrer;
