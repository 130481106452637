import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import Swal from "sweetalert2";
import {
  state_Extraction,
  state_Tranfer,
  get_state_config,
} from "../redux/actions";
import { useDispatch } from "react-redux";

const Config = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [amounts, setAmounts] = useState({});
  const [statuses, setStatuses] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(false);

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    const res = await dispatch(get_state_config());
    if (res?.status === 200) {
      setList(res?.msg);
      const initialAmounts = {};
      const initialStatuses = {};
      res.msg.forEach((el) => {
        initialAmounts[el.type] = el.data.amount;
        initialStatuses[el.type] = el.data.status;
      });
      setAmounts(initialAmounts);
      setStatuses(initialStatuses);
    }
  };

  const updateState = async (id, type, name) => {
    if (!amounts[type] && statuses[type]) {
      Swal.fire({ icon: "error", text: "Ingrese un monto" });
      return;
    }

    const data = {
      id,
      amount: parseFloat(amounts[type]),
      status: statuses[type],
    };
    setBtnDisabled(true);

    try {
      let response;
      switch (type) {
        case "TRA_USER_GEN":
          response = await dispatch(state_Tranfer(data));
          break;
        case "BTN_USER_GEN":
          // response = await dispatch(state_BtnSimple(data));
          break;
        case "EXT_USER_GEN":
          response = await dispatch(state_Extraction(data));
          break;
        default:
          return;
      }

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: `Se ${statuses[type] ? "habilitó" : "inhabilitó"} ${name}`,
        });
      } else {
        Swal.fire({ icon: "error", title: "Error", text: response?.msg });
      }
      getStates();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar el estado",
      });
    } finally {
      setBtnDisabled(false);
    }
  };

  return (
    <div className="container">
      <h5 className="ms-3 mb-4">Configuraciones</h5>
      <div className="justify-content-start">
        {list.map((el, i) =>
          el?.type !== "BTN_USER_GEN" ? (
            <>
              <div className="text-primary mb-3 ms-3 " role="alert">
               Monto permitido para {el.name}
              </div>
              <div
                key={el._id}
                className="d-flex align-items-center justify-content-between mb-3 "
              >
              
                <label>Monto:</label>
                <input
                  placeholder="Monto.."
                  className="form-control me-3"
                  value={amounts[el.type] || ""}
                  onChange={(e) =>
                    setAmounts((prev) => ({
                      ...prev,
                      [el.type]: e.target.value,
                    }))
                  }
                  type="text"
                  style={{ width: 200 }}
                />

                <label>Estado:</label>
                <Switch
                  onChange={(checked) =>
                    setStatuses((prev) => ({ ...prev, [el.type]: checked }))
                  }
                  checked={statuses[el.type] || false}
                />
                <label>{statuses[el.type] ? "Activo" : "Inactivo"}</label>

                <button
                  disabled={btnDisabled}
                  className="btn btn-primary me-3"
                  onClick={() => updateState(el._id, el.type, el.name)}
                >
                  Actualizar
                </button>
              </div>

              <hr />
            </>
          ) : (
            <div></div>
          )
        )}
      </div>
    </div>
  );
};

export default Config;
