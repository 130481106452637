import React from "react";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import {
  update_status_QR,
  get_passenger_QR,
  update_status_NFC,
  get_passenger_NFC,
  get_events_nfc,
} from "../../redux/actions";
import Modal from "react-modal";
import styles from "../../styles/Users.module.css";
import Switch from "react-switch";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { CiCircleMore } from "react-icons/ci";
import { EventsQr } from "./EventsNfc";
const NfcDetail = ({ closeModal, idUser, setListNfc, data }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [selectId, setSelectId] = React.useState("");
  const [status, setStatus] = React.useState(null);
  const [disabledBaja, setDisabledBaja] = React.useState(false);
  const [openListNfc, setOpenListNfc] = React.useState(false);

  useEffect(() => {
  
  }, [msg,status, disabledBaja])
  const update = async () => {
    let newStatus = status;
  
    if (status === "Activo") {
      newStatus = 1;
    } else if (status === "Suspendido") {
      newStatus = 2;
    } else if (status === "Baja") {
      newStatus = 3;
    }
  
    // Verificar si el motivo está vacío
    if (msg === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Seleccione un motivo",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
  
    const body = {
      _id: selectId,
      status:parseInt(newStatus),
      msg: msg,
    };
 
    // Si es "Baja" (3), mostrar la alerta antes del dispatch
    if (newStatus === "3" ) {
      Swal.fire({
        title: "¿Está seguro que desea dar de baja?",
        text: "Esta acción no se puede revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText:"Cancelar",
        confirmButtonText: "Sí, dar de baja",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(update_status_NFC(body));
          handleResponse(response);
          setDisabledBaja(true)
        }
      });
    } else {
      // Si no es "Baja", hacer el dispatch directamente
      const response = await dispatch(update_status_NFC(body));
      handleResponse(response);
    }
  };
  
  // Función para manejar la respuesta del dispatch
  const handleResponse = async (response) => {
    if (response?.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Actualizado",
        text: response?.msg,
        showConfirmButton: false,
        timer: 3000,
      }).then(async () => {
        setMsg("");
        //console.log(idUser)
        let res = await dispatch(get_passenger_NFC(idUser))
        if(res.status === 200){
          setListNfc(res?.data);
          setModal(false);

        }
       // console.log(res.data)
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: response?.msg || "Ocurrió un error al actualizar",
        showConfirmButton: false,
        timer: 4000,
      });
    }
  };
  

  const openModalUpdate = (id, status) => {
    setDisabledBaja(false)
  
    
    if (status === "Activo") {
      setStatus("1");
    } else if (status === "Suspendido") { 
      setStatus("2");
    } else if (status === "Baja") {
      setDisabledBaja(true)
      setStatus("3");
    }
  
    setSelectId(id);
    setModal(true);
  };
  
  const statusChange = (e) => {
 setMsg("")
    setStatus(e)
  }

  const eventsNfc = (id) => {
  dispatch(get_events_nfc(id))
  setOpenListNfc(true)
  };

  return (
    <div className="container">
      <Link onClick={closeModal} className="me-2">
        <IoArrowBackCircleOutline
          style={{ fontSize: "30px", color: "orange" }}
        />
      </Link>
      {
        data?.length ?(
      <div className="card p-3 mb-2">
        <h5>Información NFC pasajero </h5>
        <table className="table mt-4">
          <thead>
            <tr>
              <th scope="col">Estado</th>
              <th scope="col">Fecha de emisión</th>

              <th scope="col">Actualizar</th>
              <th scope="col">Eventos</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item._id}>
                <td>{item.status}</td>
                <td>{item.emision}</td>

                <td>
                  <button
                    className="btn"
                    onClick={() => openModalUpdate(item._id, item.status,)}
                  >
                    <FiEdit fontSize={22} />
                  </button>
                </td>
                <td>
                <button
                    className="btn"
                    onClick={() => eventsNfc(item._id)}
                  >
                    <CiCircleMore fontSize={22} />
                  </button>
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

        ):  <p className="alert alert-info mt-5">sin datos </p>
      }
      <Modal
        isOpen={modal}
        className={styles.modal}
        overlayClassName={styles.Overlay} //fondo atras
        onRequestClose={() => setModal(false)}
        contentLabel="Detalle Qr del usuario"
      >
        <div className="d-flex justify-content-center mt-5">
          <Link onClick={() => setModal(false)} className="me-2">
            <IoArrowBackCircleOutline
              style={{ fontSize: "30px", color: "orange" }}
            />
          </Link>

          <div className="card p-2 " style={{ width: "60%" }}>
          {
            disabledBaja ? <div className="alert alert-danger mt-2" role="alert">NFC dado de baja</div>:(
              <>
            <h5 className="text-center">Actualizar Estado</h5>
            <div className="d-flex align-items-center ">
              <label htmlFor="" className="me-4">
                Estado{" "}
              </label>
              <select 
                className="form-control" 
                value={status} // Esto mantiene el valor seleccionado
                onChange={(e) => statusChange(e.target.value) }
              >
                <option value="1">Activo</option>
                <option value="2">Suspendido</option>
                <option value="3">Baja</option>
              </select>

            </div>
            <div className="d-flex align-items-center py-3">
              <label className="me-4" htmlFor="">
                Motivo
              </label>
              <select
          
                value={msg}
                className="form-control"
                onChange={(e) => setMsg(e.target.value)}
              >
                <option value="" hidden>
                  Seleccionar
                </option>

                <option value="Solicitud de reactivar" disabled={status !== "1"}>
                  Solicitud de reactivar
                </option>
                <option value="Suspendido por uso indebido" disabled={status !== "2"}>
                  Suspendido por uso indebido
                </option>
                <option value="Solicitud de suspender" disabled={status !== "2"}>
                  Solicitud de suspender
                </option>
                <option value="Solicitud de baja" disabled={status !== "3"}>
                  Solicitud de baja
                </option>
                <option value="Baja por extravio" disabled={status !== "3"}>
                 Baja por extravio
                </option>
              </select>
              <button
                onClick={update}
                className="btn p-2 btn-sm ms-2 text-white"
                style={{ backgroundColor: "var(--naranja)" }}
              >
                Guardar
              </button>
            </div>
            </>
            )
           }
          </div>
        </div>
      </Modal>
        {/* Lista eventos qr */}
        <Modal
        isOpen={openListNfc}
        className={styles.modal}
        overlayClassName={styles.Overlay} //fondo atras
        onRequestClose={() => setOpenListNfc(false)}
        contentLabel="Eventos Nfc del usuario"
      >
        <EventsQr closeModalQr={setOpenListNfc}/>
      </Modal>
    </div>
  );
};

export default NfcDetail;
